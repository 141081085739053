/* Hide the default radio button */
.custom-radio input[type="radio"] {
  display: none;
}

.custom-radio-button {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin-right: 10px;
  display: inline-block;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-radio input[type="radio"]:checked + .custom-radio-button {
  background: white;
  border: 3px solid #442208 !important;
}

.custom-radio input[type="radio"] + .custom-radio-button {
  border: 3px solid rgba(0, 0, 0, 0.25);
}

.custom-radio-button:after {
  content: "";
  position: absolute;
  top: 49.5%;
  left: 49.5%;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  background-color: #442208;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.custom-radio input[type="radio"]:checked + .custom-radio-button:after {
  opacity: 1;
}

/* Hide the default checkbox */
.custom-checkbox input[type="checkbox"] {
  display: none;
}

/* Style the custom checkbox */
.custom-checkbox {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #666;
}

.custom-checkbox-box {
  width: 20px;
  height: 20px;
  border: 2px solid #e1d0ef; /* Brown border */
  display: inline-block;
  margin-right: 10px;
  position: relative;
  background-color: white;
}

.custom-checkbox input[type="checkbox"]:checked + .custom-checkbox-box {
  background-color: #5a3d31; /* Brown background when checked */
  border: 2px solid #5a3d31; /* Brown border */
}

.custom-checkbox-box:after {
  content: "";
  position: absolute;
  top: 10%;
  left: 35%;
  transform: translate(-50%, -50%);
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg); /* Adjust rotation for a perfect checkmark */
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.custom-checkbox input[type="checkbox"]:checked + .custom-checkbox-box:after {
  opacity: 1;
}
